<template>
<div class="home-page main-page">
  <div class="icon-logo">
    <img src="@/assets/img/icon-logo.png"/>
  </div>
  <div class="title">
    <img src="@/assets/img/main-title.png" class="main-title"/>
    <img src="@/assets/img/tip.png" class="tip"/>
  </div>
  <div class="content">
    <div class="item" v-for="(item, index) in dataList" :key="index">
      <div class="sub-title" :style="{width: (item.width ? `${item.width}rem` : '')}">{{item.title}}</div>
      <van-swipe class="swipe-panel" :autoplay="3000" indicator-color="white" :stop-propagation="false" @touchstart.native='touchStart' @touchmove.native='touchMove'>
        <van-swipe-item v-for="(swipe, i) in item.swipeList" :key="i" @click.stop="goToPage(swipe.routeName)">
          <div class="swipe-item" :style="{'background-image': `url(${swipe.img})`}">{{swipe.name}}</div>
        </van-swipe-item>
      </van-swipe>
      <div class="arrow-group">
        <img src="@/assets/img/arrow-right.png"/>
        <img src="@/assets/img/arrow-right.png"/>
        <img src="@/assets/img/arrow-right.png"/>
      </div>
      <img src="@/assets/img/swipe-bottom.png" class="swipe-bottom"/>
    </div>
  </div>
  <div class="slogan">
    <img src="@/assets/img/slogan.png"/>
    <p>打造伪造检测全技术栈</p>
  </div>
  <div class="footer">AI技术由中科睿鉴提供</div>
</div>
</template>

<script>
const DATA_LIST = [
  {title: '公共安全', swipeList: [
    {name: '深度合成智能检测', img: require('@/assets/img/banner1.png'), routeName: 'DeepDetection'},
    {name: '深度伪造溯源', img: require('@/assets/img/banner3.png'), routeName: 'DeepForgeTrace'},
    {name: '深伪检测一体机体验', img: require('@/assets/img/banner4.png'), routeName: 'IntegratedMachine'},
    // {name: '音视频伪造及其解决方案', img: require('@/assets/img/banner6.png'), routeName: 'VideoAttack'},
    {name: '伪造攻击与防御', img: require('@/assets/img/banner7.png'), routeName: 'FakeAndAttack'},
  ]},
  {title: '金融安全', swipeList: [
    {name: 'PS合成检测', img: require('@/assets/img/banner2.png'), routeName: 'ContractDetection'},
    {name: '卡证OCR识别', img: require('@/assets/img/banner5.png'), routeName: 'CardDetection'},
    // {name: '身份认证伪造攻击及其解决方案', img: require('@/assets/img/banner7.png'), routeName: 'FakeFaceSolution'},
  ]},
  {title: '媒体内容安全', swipeList: [
    {name: '多媒体违规内容检查', img: require('@/assets/img/banner8.png'), routeName: 'TextDetection'}
  ], width: 2.7},
];
export default {
  name: "home",
  components: {
  },
  data(){
    return {
      dataList: DATA_LIST,
      moveTime: 0, // 小于两次都不算滑动，因为部分浏览器点击也会触发一次move
    };
  },
  mounted() {
  },
  methods: {
    touchMove(e) {
      this.moveTime++;
      console.log('move')
    },
    touchStart(e) {
      this.moveTime = 0;
      console.log('start')
    },
    goToPage(routeName) {
      if (this.moveTime <= 1) {
        this.$router.push({
          name: routeName,
        });
      }
    }
  }
};
</script>

<style lang="less">
@import '../../assets/less/home';
</style>
